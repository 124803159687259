





















































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Station } from '@/models/station.model';
import { StationTreeOptions } from '@/models/app.model';

@Component({
  name: 'StationsSelectTree',
})
export default class StationsSelectTree extends Vue {
  @Prop() public model!: string[] | null;
  @Prop() public stations!: Station[];
  @Prop() public disabled?: boolean;
  @Prop() public showRegionalWarning?: boolean;

  public innerModel: string[] | null = [];

  public stationsTree: StationTreeOptions[] = [];
  public menu: boolean = false;

  @Emit()
  public onChanged() {
    return this.innerModel;
  }

  public mounted() {
    this.onModelChange();
    this.parseStations();
  }

  @Watch('stations')
  public onStationsChange() {
    this.parseStations();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }

  public onClear() {
    this.innerModel = [];
    this.onChange();
  }

  public onChange() {
    this.$emit('update:model', this.innerModel);
    this.onChanged();
  }

  public toggleAll() {
    if (this.allStationsSelected()) {
      this.innerModel = [];
    } else {
      this.innerModel = this.stations.map((station) => station.stationId);
    }
    this.onChange();
  }

  public allStationsSelected() {
    return this.innerModel ? this.innerModel.length === this.stations.length : false;
  }

  private parseStations() {
    const groupByRegion = _(this.stations).sortBy(['regionId', 'stationId']).groupBy('regionId');

    this.stationsTree =
      _.size(groupByRegion.value()) > 1
        ? groupByRegion
            .map((region, index) => {
              return {
                id: index,
                name: index,
                children: region.map((station) => ({ id: station.stationId, name: station.stationId })),
              };
            })
            .sortBy()
            .value()
        : this.stations.map((station) => {
            return {
              id: station.stationId,
              name: station.stationId,
              children: [],
            };
          });
  }
}
